import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Flex } from '@square-enix-private/vaquita-ui';

import {
  Platforms,
  Studios,
  LegalContent,
  FooterContainer,
  Links,
  Divider,
  SocialLinks,
  USK,
  Ratings,
  LocaleSwitcher
} from './elements';

const Footer = ({
  studios,
  ratings,
  platforms,
  legal,
  links,
  uskLegal,
  internalLinks,
  socialLinks,
  locale,
  heading
}) => (
  <FooterContainer p="large">
    <LocaleSwitcher locale={locale} />
    <SocialLinks data={socialLinks} />
    <Studios data={studios} />
    <Divider />
    <Platforms data={platforms} />
    <Divider />
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection={['column', 'row']}
    >
      <Ratings data={ratings} />
      <USK uskLegal={uskLegal} />
    </Flex>
    <LegalContent data={legal} uskLegal={uskLegal} />

    <Heading as="h1" fontSize="xsmall">
      {heading}
    </Heading>
    <Links data={links} />
    <Links data={internalLinks} />
  </FooterContainer>
);

Footer.propTypes = {
  studios: PropTypes.array,
  ratings: PropTypes.array,
  platforms: PropTypes.array,
  legal: PropTypes.string,
  uskLegal: PropTypes.object,
  links: PropTypes.array,
  internalLinks: PropTypes.array,
  socialLinks: PropTypes.array,
  locale: PropTypes.node.isRequired,
  heading: PropTypes.string
};

Footer.defaultProps = {
  studios: [],
  ratings: [],
  platforms: [],
  legal: '',
  uskLegal: null,
  links: [],
  internalLinks: [],
  socialLinks: [],
  heading: ''
};

export {
  Platforms,
  Studios,
  LegalContent,
  FooterContainer,
  SocialLinks,
  USK,
  Ratings,
  LocaleSwitcher
};

export default Footer;
